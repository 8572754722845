/* You can add global styles to this file, and also import other style files */
//@import "~bootstrap/dist/css/bootstrap.css";

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;800;900&display=swap');
@import '~swiper/swiper.min.css';
//@import '~swiper/modules/navigation/navigation.min.css';
//@import '~swiper/modules/pagination/pagination.min.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Red Hat Display', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --gifton-black: #212121;
  --gifton-red: #CA2B21;
  --gifton-grey: #9C9C9C;
  --gifton-light-grey: #DDDD;
  --gifton-blue: #2186CA;
  --gifton-green: #2C997C;
  --gifton-input-bg: #F2F2F2;
}

.header-footer-none {
  header,
  footer {
    display: none;
  }
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
  overflow: hidden;
}

.page-homepage {
  .app-footer-bg {
    display: none;
  }
}

.page-processing {
  overflow: hidden;

  &::before {
    content: "";
    width: 150px;
    height: 150px;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 100%;
    border: 1px solid #fff;
    z-index: 8;
    animation: logo-color-animate 1s forwards infinite;
  }

  &::after {
    content: "";
    display: block;
    width: 125px;
    height: 48px;
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: url("assets/images/desktop-logo.png") no-repeat;
    transition: .3s;
    z-index: 8;
  }

  .overlay {
    display: block;
  }
}

@keyframes logo-color-animate {
  0%, 100% {
    border: 1px solid #fff;
  }
  50% {
    border: 1px solid var(--gifton-red);
  }

}

.otp-error-msg {
  display: none;
}

.modal-open {
  overflow: hidden;

  .overlay {
    display: block;
  }

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  .modal-dialog {
    max-width: 520px;
    width: 100%;
  }

  .modal-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto !important;
  }

  .modal-content {
    width: 100%;
    background-color: #fff;
    border-radius: 24px;
    outline: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    .modal-body {
      padding: 25px;

      .modal-top-title {
        font-weight: 900;
        font-size: 11px;
        margin-bottom: 15px;
        letter-spacing: 0.88px;
      }

      .modal-login-title {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 40px;
      }

      .modal-go-register {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 10px;
        color: var(--gifton-grey);
        font-size: 14px;
        font-weight: 500;

        a {
          color: var(--gifton-red);
          text-decoration: none;
          font-size: 16px;
          padding-left: 5px;
          font-weight: bold;
        }
      }

      .modal-title {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 15px;
      }

      .modal-desc {
        font-size: 16px;
        color: var(--gifton-grey);
        font-weight: 500;
      }

      .modal-buttons {
        display: flex;

        .btn:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  .modal-footer {
    display: none;
  }
}

.gifton-holder {
  padding-top: 80px;
  padding-bottom: 80px;

  &.back {
    padding-top: 55px;
  }
}

.gifton-container {
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 60px 0 rgba(4, 6, 15, 0.05);
}

.btn {
  width: auto;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  background: none;
  border: none;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  outline: none;

  &.disabled {
    background: var(--gifton-grey);
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
  }
}

.go-back {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  text-decoration: none;
  color: var(--gifton-black);

  span {
    margin-left: 15px;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 0.88px;
  }
}

.btn-primary {
  width: 100%;
  height: 58px;
  color: #fff;
  background: var(--gifton-red);
  box-shadow: 4px 8px 24px rgba(220, 28, 6, 0.25);
  border-radius: 100px;
}

.btn-secondary {
  width: 100%;
  height: 58px;
  color: var(--gifton-black);
  border: 1px solid var(--gifton-light-grey);
  border-radius: 100px;
}

.input-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 40px;

  .input-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    .w-50 {
      width: calc(50% - 7.5px);
    }
  }

  input,
  .input {
    width: 100%;

    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      opacity: .4;
    }
  }
}

.input,
input {
  position: relative;
  font-size: 14px;
  background: var(--gifton-input-bg);
  border: none;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 0 15px;
  outline: none;

  &.otp {
    width: 65px;
    height: 80px;
    text-align: center;
    font-size: 24px;

    &.disabled {
      pointer-events: none;
    }
  }

  &.textarea {
    height: 75px;
    resize: none;
    padding: 18px 12px;
  }
}

.toggle {
  position: relative;

  input {
    width: 100%;
    height: 28px;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;

    &:checked + label {
      background: url("assets/images/checkbox.svg") no-repeat;
      border: none;
      background-size: 100%;
    }
  }

  label {
    width: 17px;
    height: 17px;
    display: inline-flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    border: 1px solid rgba(129, 129, 155, 0.2);
    background-color: rgba(0, 0, 0, 0);
  }
}

.otp-inputs {
  margin: 30px auto;
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.otp-error {
    .input.otp {
      border: 1px solid var(--gifton-red);
    }
  }
}

.input-name {
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 0.88px;
}


.svg-purchased {
  display: block;
  background: url("assets/images/gift-icon.svg");
  width: 24px;
  height: 24px;
}

.section-bg-1 {
  background: #f2f2f2;
}

.section-bg-2 {
  background: #FFF1F1;
}

.d-flex {
  display: flex;
}

.ml-10 {
  margin-left: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.row {
  display: flex;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.desktop-none {
  display: none;
}

.mobile-none {
  display: block;
}

.container-max-720 {
  max-width: 720px;
  margin: auto;
}

.container-max-800 {
  max-width: 800px;
  margin: auto;
}

.container-max-992 {
  max-width: 992px;
  margin: auto;
}

.container-max-1240 {
  max-width: 1240px;
  margin: auto;
}

.error-msg {
  font-size: 14px;
  color: #ca2b21;
  font-weight: 400;
  padding-left: 5px;
  padding-top: 5px;
  display: block;
}

.otp-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  .otp-image-container {
    display: flex;
    align-items: center;

    .otp-image {
      display: block;
    }

    .otp-error-image {
      display: none;
    }
  }

  span {
    font-size: 18px;
    font-weight: bold;
    margin-left: 5px;
  }

  .retry-otp-code {
    color: var(--gifton-blue);
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
  }
}

.otp-error {
  .otp-line .otp-image-container {
    color: var(--gifton-red);

    .otp-image {
      display: none !important;
    }

    .otp-error-image {
      display: block !important;
    }
  }

  .otp-inputs {
    input.otp,
    .input.otp {
      border: 1px solid var(--gifton-red);
    }
  }

  .otp-error-msg {
    display: flex;
    align-items: center;
    color: var(--gifton-red);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;

    img {
      margin-right: 10px;
    }
  }
}

.d-none {
  display: none !important;
}

.page-my-account {
  .account-holder {
    display: flex;
  }

  .acc-info-container {
    margin-left: 40px;
    width: 100%;

    .gifton-container {
      width: 450px;
    }
  }
}

.global-notify {
  width: 100%;
  position: fixed;
  top: 15px;
  text-align: center;
  z-index: 6;
  animation: global-notify-animate .3s;

  .global-notify-message {
    display: inline-block;
    padding: 17px 25px;
    height: 55px;
    border-radius: 12px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &.blue {
      background: var(--gifton-blue);
      color: #fff;
    }

    &.error {
      background: var(--gifton-red);
      color: #fff;
    }
  }
}

.toggle-wrapper {
  display: block;
  width: 45px;
  height: 25px;
  cursor: pointer;
  position: relative;

  input[type="checkbox"] {
    display: none;

    &:checked + .toggle-slider {
      background-color: #44cc66;

      &::after {
        left: calc(100% - 22px);
      }
    }
  }

  .toggle-slider {
    background-color: #ccc;
    position: absolute;
    border-radius: 100px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;

    &::after {
      position: absolute;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      content: "";
      width: 19px;
      height: 19px;
      border-radius: 50%;
      left: 3px;
      top: 3px;
      background-color: #fff;
    }
  }
}

.paragraph-font {
  font-size: 14px;
  line-height: 25px;

  p b {
    font-size: 16px;
  }
  a {
    color: var(--gifton-red);
  }
}

.text-hide {
  color: transparent;
}

@keyframes global-notify-animate {
  0% {
    top: 0;
  }
  100% {
    top: 15px;
  }
}

@media (max-width: 768px) {
  .mobile-holder {
    padding: 20px;
  }
  .page-my-account {
    .account-holder {
      flex-direction: column;
    }

    .acc-info-container {
      margin: 20px 0;

      .gifton-container {
        .menu-holder {
          padding: 0 25px;
        }
      }
    }
  }
  .header-footer-none-mobile {
    header,
    footer {
      display: none;
    }
  }
  .otp-inputs {
    width: 100%;

    .input.otp {
      width: 45px;
      height: 60px;
      margin-right: 5px;
    }
  }
  .gifton-holder {
    padding: 0 !important;
  }
  .go-back {
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    left: 15px;
    top: 75px;

    span {
      display: none;
    }
  }
  .container-max-720 {
    padding: 0 25px;
  }
  .desktop-none {
    display: block;
  }
  .mobile-none {
    display: none;
  }
  .modal-otp {
    .modal-buttons {
      flex-direction: column-reverse;

      .btn:last-child {
        margin-bottom: 10px;
      }
    }
  }
  .gifton-container {
    all: initial;

    .pdp {
      flex-direction: column;

      .pdp-info-container {
        padding: 0 25px;
        margin-left: 0;
      }
    }
  }
  .section-bg-1 {
    background: none;
  }
  .modal {
    .modal-footer {
      width: 100%;
      margin-top: 15px;
      display: block;

      .btn {
        background: #fff;
        font-weight: bold;
      }
    }

    .modal-dialog {
      padding: 0 20px;
    }
  }
}
